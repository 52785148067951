import React from 'react';
import Layout from '../components/layout';
import Intro from '../components/intro';
import styled from '@emotion/styled';
import Planning from '../assets/planning.svg';
import Design from '../assets/design.svg';
import Development from '../assets/development.svg';
import Testing from '../assets/testing.svg';
import Launch from '../assets/launch.svg';
import Maintenance from '../assets/maintenance.svg';
import Connector from '../assets/connector.svg';

const Stage = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media only screen and (min-width : 550px) {
    &:nth-of-type(odd) div {
      order: 1;
    }
  }

  @media only screen and (max-width : 550px) {
    flex-direction: column;
  }
`,
Stages = styled('div')`
  counter-reset: stage;
  display: flex;
  flex-direction: column;
  align-items: center;

  .connector {
    max-width: 525px;
    margin: -100px 0;
    z-index: 1;
  }

  .connector:nth-of-type(even) {
    transform: scaleY(-1);
  }

  @media only screen and (max-width : 550px) {
    margin-bottom: 2rem;

    .connector { display: none; }
  }
`,
Description = styled('div')`
  order: 2;
  border-radius: 15px;
  padding: 1rem 1rem 2rem;
  width: 48%;
  z-index: 1000;
  background: #F3F3F3;

  @media only screen and (max-width : 550px) {
    width: 100%;
    padding-top: 50px;
    margin-bottom: 20px;

    &::before {
      counter-increment: stage;
      content: "Stage " counter(stage) ": ";
      font-size: 10pt;
      text-transform: uppercase;
  }
`,
SvgImage = styled('div')`
  width: 48%;

  img { max-height: 250px; }

  @media only screen and (max-width : 550px) {
    order: 1;
    width: 100%;
    position: relative;
    margin-bottom: -50px;
    z-index: 1000;
  }
`,
Process = () => (
    <Layout page="Our Process">
      <Intro>
        <h2>A tried and true process that ensures success</h2>
        <p>While our process varies slightly between projects, the fundametals remain the same - providing you with clarity
        and direction when working together so there are no surprises.</p>
      </Intro>

      <Stages>
        <Stage>
          <Description>
            <h3>Planning</h3>
            <ul>
              <li>Face to face Meetings</li>
              <li>Gathering Requirements</li>
              <li>Scoping Documentation</li>
              <li>Project Scheduling and Estimates</li>
              <li>Researching Technical Stack</li>
              <li>Understanding Competitors</li>
              <li>Engaging with Stakeholders</li>
            </ul>
          </Description>
          <SvgImage>
            <img src={Planning} alt="Planning" />
          </SvgImage>
        </Stage>
        <img src={Connector} alt="" className="connector" />

        <Stage>
          <Description>
            <h3>Design</h3>
            <ul>
              <li>Graphic Design</li>
              <li>Visual Mockups</li>
              <li>Wireframes and User Workflow</li>
              <li>Responsive Design</li>
              <li>Prototyping</li>
              <li>Client Review and Approval Cycle</li>
            </ul>
          </Description>
          <SvgImage>
            <img src={Design} alt="Design" />
          </SvgImage>
        </Stage>
        <img src={Connector} alt="" className="connector" />

        <Stage>
          <Description>
            <h3>Development</h3>
            <ul>
              <li>Database Development</li>
              <li>API Development</li>
              <li>Frontend Development</li>
              <li>Systems Integration</li>
              <li>Content Development</li>
              <li>Peer Code Review</li>
              <li>Client Review and Feedback</li>
            </ul>
          </Description>
          <SvgImage>
            <img src={Development} alt="Development" />
          </SvgImage>
        </Stage>
        <img src={Connector} alt="" className="connector" />

        <Stage>
          <Description>
            <h3>Testing</h3>
            <ul>
              <li>Unit and Integration Testing</li>
              <li>User Acceptance Testing</li>
              <li>Automated End-to-End Testing</li>
              <li>Cross-Browser Compatibility</li>
              <li>Cross-Device Testing</li>
              <li>Security Review</li>
              <li>Client Review and Feedback</li>
            </ul>
          </Description>
          <SvgImage>
            <img src={Testing} alt="Testing" />
          </SvgImage>
        </Stage>
        <img src={Connector} alt="" className="connector" />

        <Stage>
          <Description>
            <h3>Launch</h3>
            <ul>
              <li>Client Approval</li>
              <li>Server and Hosting Configuration</li>
              <li>Data Migration</li>
              <li>Analytics Configuration</li>
              <li>User Training</li>
              <li>Release Planning</li>
            </ul>
          </Description>
          <SvgImage>
            <img src={Launch} alt="Launch" />
          </SvgImage>
        </Stage>
        <img src={Connector} alt="" className="connector" />

        <Stage>
          <Description>
            <h3>Maintenance and Support</h3>
            <ul>
              <li>Site Improvements</li>
              <li>Performance Tuning and Optimisation</li>
              <li>System Backups and Monitoring</li>
              <li>Technical Support</li>
              <li>End User Support</li>
            </ul>
          </Description>
          <SvgImage>
            <img src={Maintenance} alt="Maintenance" />
          </SvgImage>
        </Stage>
      </Stages>
    </Layout>
);

export default Process;
